<template>
  <validation-observer v-slot="{ invalid }">
        <div class="columns">
            <div class="column">
                <validation-provider
                    tag="div"
                    class="field"
                    v-slot="{ errors, failed }"
                    rules="required"
                >
                  <div class="control">
                    <label class="label">
                      Login Identifier
                    </label>
                    <div class="select is-fullwidth">
                      <select v-model="ic.loginIdentifier">
                        <option :value="null">Please select an identifier</option>
                        <option value="USERNAME">Username</option>
                        <option value="EMAIL">Email</option>
                      </select>
                    </div>
                  </div>
                  <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
                </validation-provider>
                <validation-provider
                    tag="div"
                    class="field"
                    v-slot="{ errors, failed }"
                    rules="required|max:255"
                    >                
                    <div class="control">
                        <label class="label">Username Placeholder*</label>
                        <input name="Username Placeholder" type="text" class="input" v-model="ic.usernamePlaceholder" />
                    </div>
                    <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
                </validation-provider>
                <validation-provider
                    tag="div"
                    class="field"
                    v-slot="{ errors, failed }"
                    rules="required|max:255"
                    >                
                    <div class="control">
                        <label class="label">Password Placeholder*</label>
                        <input name="Password Placeholder" type="text" class="input" v-model="ic.passwordPlaceholder" />
                    </div>
                    <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
                </validation-provider>
                <validation-provider
                    tag="div"
                    class="field"
                    v-slot="{ errors, failed }"
                    rules="max:1000"
                    >
                    <div class="control">
                        <label class="label">Email Disclosure</label>
                        <textarea
                            class="textarea"
                            v-model="ic.emailDisclosure"
                        ></textarea>
                    </div>
                    <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
                </validation-provider>
                <div class="field">
                    <p class="content"><em>* indicates required field</em></p>
                </div>
                <div class="field">
                    <div class="buttons">
                        <button class="button is-success" :disabled="invalid" @click="save">
                        Save
                        </button>
                        <button class="button is-light" @click="revert">
                        Revert
                        </button>
                    </div>
                </div>
            </div>
            <div class="column">
                <figure class="image">
                    <img src="@/assets/login.png" alt="Login Screen" />
                </figure>
            </div>
        </div>
    </validation-observer>
</template>
<script>
import { mapActions } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
    components: { ValidationObserver, ValidationProvider },
    data() {
        return {
            ic: {
                usernamePlaceholder: null,
                passwordPlaceholder: null,
                loginIdentifier: null,
                emailDisclosure: null
            }
        };
    },
    methods: {
        ...mapActions("inst", ["fetchInstances"]),
        ...mapActions([
            "fetchPaymentPortalGlobalConfig",
            "fetchPaymentPortalInstanceConfig",
            "saveInstanceConfig"
        ]),
        loadView(id) {
            this.ic.instanceId = id;
            this.ic.usernamePlaceholder = this.$store.state.instanceConfig.usernamePlaceholder;
            this.ic.passwordPlaceholder = this.$store.state.instanceConfig.passwordPlaceholder;
            this.ic.loginIdentifier = this.$store.state.instanceConfig.loginIdentifier;
            this.ic.emailDisclosure = this.$store.state.instanceConfig.emailDisclosure;
        },
        async save() {
            window.scrollTo(0, 0);
            await this.saveInstanceConfig(this.ic);
        },
        async revert() {
            await this.fetchPaymentPortalInstanceConfig(this.$route.params.id);
            this.loadView();
        }
    },
    beforeRouteEnter(to, from, next) {
        next(async vm => {
            vm.$store.commit("SHOW_PROGRESS");
            vm.$store.commit("HIDE_NOTIFICATION");
            try {
                await vm.fetchPaymentPortalGlobalConfig();
                await vm.fetchPaymentPortalInstanceConfig(to.params.id);
                await vm.fetchInstances();
                vm.loadView.call(vm, to.params.id);
            } catch (e) {
                vm.$store.commit(
                "SHOW_NOTIFICATION",
                `Error loading InstanceMain; ${e}`
                );
            } finally {
                vm.$store.commit("HIDE_PROGRESS");
            }
        });
    },
    async beforeRouteUpdate(to, from, next) {
        this.$store.commit("SHOW_PROGRESS");
        this.$store.commit("HIDE_NOTIFICATION");
        try {
            await this.fetchPaymentPortalInstanceConfig(to.params.id);
            this.loadView(to.params.id);
        } catch (e) {
            this.$store.commit(
                "SHOW_NOTIFICATION",
                `Error loading instance id=${to.params.id}; ${e}`
            );
        } finally {
            this.$store.commit("HIDE_PROGRESS");
            next();
        }
    }
};
</script>
