var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v(" Login Identifier ")]),_c('div',{staticClass:"select is-fullwidth"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.ic.loginIdentifier),expression:"ic.loginIdentifier"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.ic, "loginIdentifier", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":null}},[_vm._v("Please select an identifier")]),_c('option',{attrs:{"value":"USERNAME"}},[_vm._v("Username")]),_c('option',{attrs:{"value":"EMAIL"}},[_vm._v("Email")])])])]),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Username Placeholder*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ic.usernamePlaceholder),expression:"ic.usernamePlaceholder"}],staticClass:"input",attrs:{"name":"Username Placeholder","type":"text"},domProps:{"value":(_vm.ic.usernamePlaceholder)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ic, "usernamePlaceholder", $event.target.value)}}})]),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Password Placeholder*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ic.passwordPlaceholder),expression:"ic.passwordPlaceholder"}],staticClass:"input",attrs:{"name":"Password Placeholder","type":"text"},domProps:{"value":(_vm.ic.passwordPlaceholder)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ic, "passwordPlaceholder", $event.target.value)}}})]),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('validation-provider',{staticClass:"field",attrs:{"tag":"div","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Email Disclosure")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.ic.emailDisclosure),expression:"ic.emailDisclosure"}],staticClass:"textarea",domProps:{"value":(_vm.ic.emailDisclosure)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ic, "emailDisclosure", $event.target.value)}}})]),(failed)?_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('div',{staticClass:"field"},[_c('p',{staticClass:"content"},[_c('em',[_vm._v("* indicates required field")])])]),_c('div',{staticClass:"field"},[_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button is-success",attrs:{"disabled":invalid},on:{"click":_vm.save}},[_vm._v(" Save ")]),_c('button',{staticClass:"button is-light",on:{"click":_vm.revert}},[_vm._v(" Revert ")])])])],1),_c('div',{staticClass:"column"},[_c('figure',{staticClass:"image"},[_c('img',{attrs:{"src":require("@/assets/login.png"),"alt":"Login Screen"}})])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }